<template>
  <div>
    <v-card>
      <v-card-title>REVISIÓN SOLICITUDES</v-card-title>
      <v-card-text>
        <!-- <botonera 
                    :botones="botones" 
                    alinear="right" 
                    @crear="agregarSolicitud"
                >
                </botonera> -->
        <v-select
          v-model="encargado_id"
          :items="encargados_filtred"
          item-text="name"
          item-value="id"
          label="Encargado área"
          color="blue darken-3"
          persistent-hint
          :reduce="(item) => item.id"
          style="max-width:180px"
          @change="loadSolicitudes()"
        />
        <v-alert
          border="left"
          outlined
          text
          :type="alert.tipo"
          v-if="alert.mostrar"
        >
          <v-row>
            <v-col>
              <h4>{{ alert.mensaje }}</h4>
            </v-col>
            <v-col class="text-right">
              <v-btn
                :color="alert.color_boton"
                elevation="2"
                small
                dark
                @click="cerrar_alert"
                >Cerrar</v-btn
              >
            </v-col>
          </v-row>
          <v-row v-if="alert.errores.length > 0">
            <v-col>
              <ul>
                <li v-for="e in alert.errores" :key="e">{{ e }}</li>
              </ul>
            </v-col>
          </v-row>
        </v-alert>
      </v-card-text>
    </v-card>
    <div class="card">
      <div class="card-header card-header-primary card-header-icon">
        <div :class="`card-icon ${color_icono}`">
          <i class="material-icons" v-show="icono">{{ icono }}</i>
        </div>
        <h4 class="card-title">
          <div class="row">
            <div class="col-md-6">
              {{ titulo }}
            </div>
          </div>
          <div class="row">
            <div class="col-md-3 px-1">
              <v-autocomplete v-model="wellboats_ids" :items="wellboats" item-text="nombre" item-value="id"
                label="Seleccione Wellboat" color="blue darken-3" persistent-hint :reduce="(item) => item.id" multiple
                :disabled="user.wellboats_id > 0"
                @change="limpiarBusqueda" ref="wellboatAutocomplete">
              </v-autocomplete>
            </div>
            <div class="col-md-2 px-1">
              <v-text-field placeholder="Número folio" v-model="folio_filtred" type="text" style="max-width:180px" />
            </div>
            <div class="col-md-5 px-1">
              <v-autocomplete v-model="materiales_ids" :items="materiales" item-text="nombre" item-value="id"
                label="Seleccione Ítem" color="blue darken-3" persistent-hint :reduce="(item) => item.id" multiple
                @change="limpiarBusqueda" ref="autocompleteMateriales">
              </v-autocomplete>
            </div>
            <div class="col-md-2 text-right">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="mx-1 mt-2" fab dark small color="primary" v-bind="attrs" v-on="on"
                    @click="loadSolicitudes()">
                    <v-icon medium style="color:white">
                      mdi-magnify
                    </v-icon>
                  </v-btn>
                </template>
                <span>Buscar</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="mx-1 mt-2" fab dark small color="warning" v-bind="attrs" v-on="on" @click="limpiar()">
                    <v-icon medium style="color:white">
                      mdi-broom
                    </v-icon>
                  </v-btn>
                </template>
                <span>Limpiar Filtro</span>
              </v-tooltip>
              <!-- <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="mx-1 mt-2" fab dark small color="success" v-bind="attrs" v-on="on" @click="loadSolicitudMateriales(null, true)">
                    <v-icon large style="color:white">
                      description
                    </v-icon>
                  </v-btn>
                </template>
                <span>Exportar Excel</span>
              </v-tooltip> -->
            </div>
            <!-- <div class="col-md-2">
                            <v-text-field
                                v-model="folio_filtred"
                                placeholder="Escriba Folio"
                                append-icon="mdi-magnify"
                                single-line
                                hide-details
                            ></v-text-field>
                        </div> -->
            <!-- <div class="col-md-2">
                            <v-text-field
                                v-model="item_filtred"
                                placeholder="Escriba Item"
                                append-icon="mdi-magnify"
                                single-line
                                hide-details
                            ></v-text-field>
                        </div> -->
            
          </div>
          <div class="row">
            <div class="col-md-3 px-1">
              <v-menu v-model="menu1" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                offset-y min-width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="inicio_formated" label="Fecha de Inicio" prepend-icon="mdi-calendar" readonly
                    v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="inicio" @input="menu1 = false" locale="es-cl" prev-icon="chevron_left"
                  next-icon="chevron_right"></v-date-picker>
              </v-menu>
            </div>
            <div class="col-md-3 px-1">
              <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                offset-y min-width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="termino_formated" label="Fecha de término" prepend-icon="mdi-calendar" readonly
                    v-bind="attrs" v-on="on" :disabled="!inicio"></v-text-field>
                </template>
                <v-date-picker v-model="termino" @input="menu2 = false" locale="es-cl" prev-icon="chevron_left"
                  next-icon="chevron_right"></v-date-picker>
              </v-menu>
            </div>
            <!-- <div class="col-md-3 px-1">
              <v-autocomplete v-model="estados_solicitudes_ids" :items="estados_solicitudes" item-text="nombre"
                item-value="id" label="Seleccione Estado" color="blue darken-3" persistent-hint
                :reduce="(item) => item.id" multiple>
              </v-autocomplete>
            </div> -->
          </div>

          <div class="row">
              <div class="col-md-4"></div>
              <div class="col-md-4" style="text-align:center">
                <p style="font-size:15px">
                  (pinchar en el botón limpiar
                  <v-btn style="width:26px;height:26px;cursor:default" fab dark small dense color="warning">
                    <v-icon large style="color:white;font-size:25px">
                      mdi-broom
                    </v-icon>
                  </v-btn>
                  para listar todas las solicitudes)
                </p>
              </div>
              <div class="col-md-4"></div>
          </div>
        </h4>
      </div>
      
      <div class="card-body">
        <spinner v-if="spinner"></spinner>
        <v-simple-table :height="table_height">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-center">WELLBOAT</th>
                <th class="text-center">FOLIO</th>
                <th class="text-center">FECHA SOLICITUD</th>
                <th class="text-center" :style="{ 'min-width': sizeSelect }">
                  ENCARGADO ÁREA
                </th>
                <th class="text-center" :style="{ 'min-width': sizeSelect2 }">
                  ÁREA
                </th>
                <th class="text-center" :style="{ 'min-width': sizeSelect2 }">
                  CATEGORÍA
                </th>
                <th class="text-center" :style="{ 'min-width': sizeSelect2 }">
                  ITEM
                </th>
                <th class="text-center">DESCRIPCIÓN</th>
                <th class="text-center">CANTIDAD SOLICITADA</th>
                <th class="text-center">ARCHIVO ADJUNTO</th>
                <th class="text-center">CANTIDAD APROBADA</th>
                <th class="text-center">ESTADO</th>
                <th class="text-center">ACCIONES</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in solicitudes"
                :key="index"
                class="text-center"
              >
                <td>
                  {{ item.wellboat }}
                </td>
                <td>
                  {{ `${item.centro_costo}-${item.folio}` }}
                </td>
                <td>
                  {{ item.fecha_solicitud }}
                </td>
                <td>
                  <v-select
                    v-model="item.encargado_id"
                    :items="encargadosFilter(item)"
                    item-text="name"
                    item-value="id"
                    label="Seleccione Encargado"
                    color="blue darken-3"
                    persistent-hint
                    :reduce="(item) => item.id"
                    class="mt-4"
                    :style="{ 'max-width': sizeSelect }"
                    @change="updateEncargado(index)"
                  />
                </td>
                <td :style="{ 'max-width': sizeSelect2 }">
                  {{ item.area }}
                </td>
                <td :style="{ 'max-width': sizeSelect2 }">
                  {{ item.categoria }}
                </td>
                <td :style="{ 'max-width': sizeSelect2 }">
                  {{ item.material_codigo }}
                </td>
                <td>
                  {{ item.descripcion }}
                </td>
                <td>
                  {{ item.cantidad | formatear_miles }}
                </td>
                <td>
                  <!-- <a
                                    :href="item.url"
                                    target="_blank"
                                    class="ma-2" >
                                        <v-icon large v-if="item && item.archivo && item.archivo.includes('.pdf')" style="color:#ef233c">
                                            mdi-file-pdf-box
                                        </v-icon>
                                        <v-icon large v-else-if="item.archivo!=null" style="color:#52b788">
                                            mdi-file-image
                                        </v-icon>
                                    </a> -->
                  <a
                    @click="downloadFile(item.archivo)"
                    target="_blank"
                    class="ma-2"
                  >
                    <v-icon
                      large
                      v-if="
                        item && item.archivo && item.archivo.includes('.pdf')
                      "
                      style="color:#ef233c"
                    >
                      mdi-file-pdf-box
                    </v-icon>
                    <v-icon
                      large
                      v-else-if="item.archivo != null"
                      style="color:#52b788"
                    >
                      mdi-file-image
                    </v-icon>
                  </a>
                </td>
                <td style="width=50px">
                  <v-text-field
                    placeholder="Cantidad Aprobada"
                    v-model="item.cantidad_aprobada"
                    type="number"
                    hide-details
                    :clearable="false"
                  />
                </td>
                <td>
                  {{ item.estado }}
                </td>
                <td>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <a
                        class="ma-2"
                        @click="aprobarSolicitud(index)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon large color="success">
                          mdi-checkbox-marked-circle
                        </v-icon>
                      </a>
                    </template>
                    <span>Aprobar Revisión</span>
                  </v-tooltip>

                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <a
                        class="ma-2"
                        @click="rechazarSolicitud(index)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon large color="red">
                          mdi-alpha-x-circle
                        </v-icon>
                      </a>
                    </template>
                    <span>Rechazar Revisión</span>
                  </v-tooltip>
                </td>

              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <div class="pagination-wrapper">
          <div class="pagination">
            <button v-bind:class="{ 'active': p.active, 'page-item': true }" :data-page="p.label" v-for="p in links"
              :key="p.label" :disabled="!p.url" @click="loadSolicitudes(p)">
              <span v-if="p.label.includes('Anterior')">←</span>
              <span v-else-if="p.label.includes('Siguiente')">→</span>
              <span v-else>{{ p.label }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <v-alert
      v-if="emptyData && load_data"
      border="right"
      color="blue-grey"
      dark
    >
      No hay solicitudes en revisión...
    </v-alert>
    <v-snackbar v-model="snackbar" :timeout="5000" :color="color">
      {{ validation_message }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import { mapMutations, mapState } from "vuex";
export default {
  data: () => ({
    sizeSelect: "300px",
    sizeSelect2: "250px",
    table_height: 850,
    spinner: false,
    titulo: "CONSOLIDADO DE SOLICITUDES A REVISAR",
    color_icono: "azul",
    icono: "list",
    data_header: null,
    activePicker: null,
    // botones: [
    //     { boton: 'crear', tooltip: 'Agregar Fila' },
    //     { boton: 'cargar', tooltip: 'Carga Masiva' },
    //     {boton:'eliminar',tooltip:'Borrar Todo'}
    // ],
    solicitudes: [],
    encargados: [],
    encargados_filtred: [],
    items: [],
    select_categoria: "",
    validation_message: "",
    color: "green",
    snackbar: false,
    data: [],
    load_data: false,
    inicio: "",
    termino: "",
    estados_solicitudes: [],
    estados_solicitudes_ids: [],
    wellboats_ids: [],
    materiales_ids:[],
    materiales:[],
    folio_filtred: "",
    menu1: false,
    menu2: false,
    // item_filtred: "",
    alert: {
      mostrar: false,
      tipo: "",
      color_boton: "",
      mensaje: "",
      errores: [],
    },
    encargado_id: null,
    page: 1,
    links: [],
  }),
  mounted() {
    this.table_height = 850;
    this.loadMateriales()
    this.loadSolicitudes();
  },
  computed: {
    ...mapState([
      "loading",
      "base_url",
      "headers",
      "headers_file",
      "wellboat",
      "user",
      "storage_url",
      "wellboats"
    ]),
    // filterSolicitud: (data) => {
    //     return data.encargado_id!="" ?
    //         data.solicitudes.filter(s => s.users_id == data.encargado_id) :
    //         data.solicitudes
    // },
    inicio_formated() {
      return this.formatDate(this.inicio);
    },
    termino_formated() {
      return this.formatDate(this.termino);
    },
    emptyData: (data) => {
      return data.solicitudes.length > 0 ? false : true;
    },
  },
  methods: {
    limpiarBusqueda() {
      // Limpia el texto de búsqueda
      this.$refs.autocompleteMateriales.$data.query = '';// Opcional: cerrar el menú después de seleccionar
      this.$refs.autocompleteMateriales.isMenuActive = false

      this.$refs.wellboatAutocomplete.$data.query = ''; // Limpia el texto de búsqueda
      this.$refs.wellboatAutocomplete.isMenuActive = false; // Cierra el menú (opcional)
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}-${month}-${year}`;
    },
    encargadosFilter(item) {
      //console.log('item',item.areas_id)
      //console.log('encargados',this.encargados)
      //console.log('',this.encargados.filter(en => en.areas_ids.includes(item.areas_id)))
      let result = this.encargados.filter((en) =>
        en.areas_ids.includes(item.areas_id)
      );
      //item.encargado_id = result[0].id
      return result;
      //let encargados  = [...this.encargados.filter(e=>e.areas_ids.includes(item.areas_id))];

      /* !encargados.map(e=>e.id).includes(item.users_id)&&(
                encargados.push(this.encargados.find(e=>e.id===item.users_id))
            ) */
      //return encargados;
    },
    downloadFile(archivo) {
      /*NO BORRAR, FUNCIONA EN LOCAL COMO EN PRODUCCION */
      let link = `${this.storage_url}solicitudes/${archivo}`;
      console.log(link);
      window.open(link);
    },
    cerrar_alert() {
      this.alert.mostrar = false;
      this.alert.tipo = "";
      this.alert.color_boton = "";
      this.alert.mensaje = "";
      this.alert.errores = [];
    },
    async loadSolicitudes(link = null) {
      
      this.spinner = true;
      this.mostrarLoading("Cargando listado de solicitudes...");
      let url = `${this.base_url}solicitudes/estado_solicitud/${1}`;
      if (link) {
        //extraer page del link
        this.page = link.url.split("?page=")[1];
      }
      let data = {
        roles: this.user.roles_ids,
        areas_ids: this.user.areas_ids,
        users_id: this.user.id,
        encargado_id: this.encargado_id,
        page: this.page,
        folio: this.folio_filtred,
        inicio: this.inicio,
        termino: this.termino,
        wellboats_id: this.user.wellboat.id,
        estados_solicitudes_ids: this.estados_solicitudes_ids.length > 0 ? this.estados_solicitudes_ids : null,
        wellboats_ids: this.wellboats_ids.length > 0 ? this.wellboats_ids : null,
        materiales_ids: this.materiales_ids.length > 0 ? this.materiales_ids : null,
        //encargado: this.encargado_id
      };
      let solicitudes = await this.axios.post(url, data, this.headers);
      console.log(solicitudes.status === 200);
      solicitudes.status === 200 &&
        new Promise(() => {
          this.table_height = null;
          console.log( solicitudes.data);

          this.links = solicitudes.data.links
          this.solicitudes = solicitudes.data.data;
          //let areasIds = solicitudes.data.data.map(s=>s.areas_id);
          this.load_data = true;
          this.ocultarLoading();
          this.loadEncargados();
        });
      this.spinner = false;
    },
    async loadMateriales() {
      this.mostrarLoading("Cargando listado de materiales...");
      let url = `${this.base_url}materiales`;
      await this.axios
        .get(url, this.headers)
        .then((response) => {
          this.materiales = response.data;
          this.ocultarLoading();
        })
        .catch((error) => {
          console.log(error);
          this.ocultarLoading();
        });
    },
    limpiar() {
      this.inicio = null;
      this.termino = null;
      this.folio_filtred = "";
      this.data_filtered = false;
      this.estados_solicitudes_ids = [];
      this.wellboats_ids = [];
      this.materiales_ids = [];
      this.solicitudes = [];
      this.page = 1;
      this.loadSolicitudes();
    },
    async loadEncargados() {
      //let url = `${this.base_url}usuarios/encargados`;
      let url = `${this.base_url}listar/encargados`;
      let data = {
        //areasIds: this.user.areas_ids,
        //roles:this.user.roles_ids,
        users_id: this.user.id,
      };
      await this.axios
        .post(url, data, this.headers)
        .then((response) => {
          this.encargados = response.data;
          this.encargados_filtred = [
            ...[{ id: "", name: "Todos" }],
            ...response.data,
          ];
          this.ocultarLoading();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    volver() {
      this.$router.push("solicitud_materiales");
    },
    showSnackBar(error) {
      if (error) {
        this.color = "red";
      } else {
        this.color = "green";
        this.validation_message = "Ejecutado exitosamente!";
      }
      this.snackbar = true;
    },
    async rechazarSolicitud(index) {
      this.spinner = true;
      let url = `${this.base_url}items/${this.solicitudes[index].id}`;
      let data = {
        estados_solicitudes_id: 3,
        revisor_fecha_aprob: new Date(),
        revisor_nombre_apellido: this.user.name,
        revisor_id: this.user.id,
        //agregar userId
      };
      this.$confirm(
        "¿Estás seguro de que deseas rechazar esta solicitud?"
      ).then(async (res) => {
        res &&
          new Promise(() => {
            this.axios
              .put(url, data, this.headers)
              .then((response) => {
                response.status === 200 &&
                  new Promise(() => {
                    this.alert.mostrar = true;
                    this.alert.tipo = "success";
                    this.alert.color_boton = "green";
                    let wellboat = this.solicitudes[index].wellboat
                      ? `wellboat ${this.solicitudes[index].wellboat}`
                      : "";
                    this.alert.mensaje = `Se ha rechazado la solicitud ${wellboat} con folio ${this.solicitudes[index].folio}`;
                    this.solicitudes.splice(index, 1);
                  });
                this.ocultarLoading();
              })
              .catch((error) => {
                console.log(error);
              });
          });
      });
      this.spinner = false;
    },
    async aprobarSolicitud(index) {
      this.cerrar_alert();
      this.spinner = true;
      let url = `${this.base_url}items/${this.solicitudes[index].id}`;
      let data = {
        estados_solicitudes_id: 2,
        revisor_cantidad_aprob: this.solicitudes[index].cantidad_aprobada
          ? this.solicitudes[index].cantidad_aprobada
          : "",
        revisor_fecha_aprob: new Date(),
        revisor_nombre_apellido: this.user.name,
        tipo: "revisor",
        cantidad: this.solicitudes[index].cantidad,
        encargado_id: this.solicitudes[index].encargado_id,
        revisor_id: this.user.id,
      };
      await this.axios
        .put(url, data, this.headers)
        .then((response) => {
          response.status === 200 &&
            new Promise(() => {
              this.alert.mostrar = true;
              this.alert.tipo = "success";
              this.alert.color_boton = "green";
              let mensaje_wellboat = "";
              this.solicitudes[index].wellboat != null &&
                (mensaje_wellboat = `de Wellboat ${this.solicitudes[index].wellboat} `);
              this.alert.mensaje = `Solicitud ${mensaje_wellboat}
                    con folio ${this.solicitudes[index].folio}
                    ha sido aprobada con éxito para el ítem: ${this.solicitudes[index].material}.
                    La cantidad aprobada es ${this.solicitudes[index].cantidad_aprobada}.`;
              this.solicitudes.splice(index, 1);
            });
          this.ocultarLoading();
        })
        .catch((error) => {
          this.alert.mostrar = true;
          this.alert.tipo = "error";
          this.alert.color_boton = "red";
          this.alert.mensaje = error.response.data.message;
          this.alert.errores = error.response.data.errors;
          console.log("errores", error.response.data);
        });
      this.spinner = false;
    },
    async updateEncargado(index) {
      this.cerrar_alert();
      this.spinner = true;
      let url = `${this.base_url}items/${this.solicitudes[index].solicitudes_id}/actualizar_encargado`;
      let data = {
        encargado_id: this.solicitudes[index].encargado_id,
        id: this.solicitudes[index].id,
      };
      await this.axios
        .post(url, data, this.headers)
        .then((response) => {
          response.status === 200 &&
            new Promise(() => {
              this.alert.mostrar = true;
              this.alert.tipo = "success";
              this.alert.color_boton = "green";
              this.alert.mensaje = `El encargado fue actualizado`;
            });
          this.ocultarLoading();
          this.loadSolicitudes();
        })
        .catch((error) => {
          let data = error.response.data.error;
          this.alert.mensaje = data;
        });
      this.spinner = false;
    },
    selectCategoria(value) {
      this.select_categoria = value;
    },
    selectItem() {
      this.select_categoria = "";
    },
    retornar_materiales(categorias_id) {
      if (categorias_id) {
        return this.items.filter((m) => m.categorias_id == categorias_id);
      }
      return [];
    },
    onlyUnique(value, index, array) {
      return array.indexOf(value) === index;
    },
    ...mapMutations(["mostrarLoading", "ocultarLoading"]),
    /* computed: {
        ...mapState(['loading', 'base_url', 'headers', 'user','storage_url']),
        }, */
  },
};
</script>
<style scoped>
th {
  background-color: #355f94 !important;
  color: #fff !important;
  border: 1px solid #fff;
}

/* th {
  background-color: #355f94 !important;
  color: #fff !important;
  border: 1px solid #fff;
} */

/* Hace que el encabezado sea fijo */
.table-container {
  overflow-y: auto;
  /* Agrega una barra de desplazamiento vertical si es necesario */
}

.table-container thead {
  position: sticky;
  top: 0;
  z-index: 1;
  /* Asegura que el encabezado esté por encima del contenido desplazable */
}

.mx-1 {
  height: 40px;
  width: 40px;
}

.smaller-icon {
  font-size: 20px;
}



/* Estilos generales */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Arial, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f0;
}

/* Contenedor con barra desplazable */
.pagination-wrapper {
  overflow-x: auto;
  /* Habilitar desplazamiento horizontal */
  padding: 10px 0;
  width: 100%;
  max-width: 100%;
  /* Ajustar el ancho máximo si lo deseas */
}

/* Barra de Paginación (sin fondo) */
.pagination {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  border-radius: 30px;
}

/* Estilo de los items de la paginación */
.page-item {
  text-decoration: none;
  color: #355f94;
  padding: 10px 15px;
  margin: 0 5px;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  border: 2px solid #355f94;
  transition: background-color 0.3s, transform 0.3s;
  background-color: white;
}

/* Páginas activas */
.page-item.active,
.page-item.active span,
.page-item.active span:hover {
  background-color: #355f94;
  /* Color de fondo para el item activo */
  color: white;
  /* Texto blanco en el item activo */
  box-shadow: 0 0 8px rgba(53, 95, 148, 0.6);
  transform: scale(1.1);
}

.pagination button {
  display: flex;
  /* Usamos flexbox en el button */
  justify-content: center;
  /* Centrar horizontalmente */
  align-items: center;
  /* Centrar verticalmente */
  padding: 5px 10px;
  /* Espaciado interno del botón */
  border: 1px solid #355f94;
  /* Borde azul */
  background-color: white;
  /* Fondo blanco */
  color: #355f94;
  /* Color de texto azul */
  cursor: pointer;
  /* Cursor de puntero */
  border-radius: 5px;
  /* Bordes redondeados */
}

.pagination button span {
  color: #355f94;
  /* Color de la letra azul */
  font-size: 16px;
  /* Tamaño de la letra */
  font-weight: bold;
  /* Peso de la fuente */
  padding: 0;
  /* Sin padding extra */
  margin: 0;
  /* Sin márgenes extra */
}

/* Efecto al pasar el mouse sobre los items inactivos */
.page-item:hover:not(.active) {
  background-color: #e1eff8;
}

/* Flechas de navegación */
.previous,
.next {
  font-weight: bold;
  font-size: 18px;
}

/* Efecto en las flechas */
.previous:hover,
.next:hover {
  background-color: #e1eff8;
}
</style>
